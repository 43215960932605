const COLUMNS = [
  {
    id: "summary",
    displayName: "Summary",
    widget: "text"
  },
  {
    id: "priority",
    displayName: "Priority",
    widget: "priority"
  }, {
    id: "status",
    displayName: "Status",
    widget: "status"
  },
  {
    id: "description",
    displayName: "Description",
    widget: "description"
  }
  , {
    id: "comments",
    displayName: "comments",
    widget: "comments"
  }, {
    id: "created",
    displayName: "Created At",
    widget: "date"
  }
];



export {
  COLUMNS
};