import React from "react";
import ArcIcon from "../../../icon";
import styles from "./status.module.scss";

function IssueType(props) {
  let { data } = props;
  return (
    <div className={styles.common}>
      <div className={`${styles.wrapper}`}>
        <ArcIcon name={data.toLowerCase()} />
        <span>{data}</span>
      </div>
    </div>
  );
}

export default IssueType;

