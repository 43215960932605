import React, { useState } from "react";
import { Modal, Button, Typography } from 'antd';
import moment from "moment";
import ArcIcon from "../../../icon";
// import { getIssueComments } from "../../../../services";
import styles from "./type.module.scss";
const { Text } = Typography;
function Comments(props) {
  let { data } = props;

  let fData = data;


  let content = data[0]?.body?.content[0]?.content[0]?.text;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    getIssue()
  };

  const handleCancel = () => {
    fData = data?.reverse();
    setIsModalOpen(true);
    setIsModalOpen(false);
  };

  const getIssue = (id) => {
    fData = data?.reverse();
    setIsModalOpen(true);
  }



  let ellipsis = true;
  return (
    <div className={styles.wrapper}>
      <div className={styles.whiteSpace} onClick={showModal}>
        {content}
      </div>
      <Modal onCancel={handleCancel} width="600px" title="Comments" open={isModalOpen}
        footer={[
          <Button key="back" type="primary" onClick={handleCancel}>
            Close
          </Button>,

        ]}
      >
        <div className={styles.flowComments}>
          {fData?.map((comment) => (
            <div className={styles.wrapperCommnets} key={comment.id}>
              <div className={styles.author}>
                <div className={`${styles.circle} ${styles[comment.author.displayName ?
                  comment.author.displayName.charAt(0).toLowerCase() : 'a']}`}>
                  {comment.author.displayName.charAt(0)}
                </div>
                <div className={styles.authInfo}>
                  <div className={styles.name}>{comment.author.displayName}</div>
                  <div className={styles.date}>{moment(comment.created).format("DD-MM-YYYY hh:mm a")}</div>
                </div>
              </div>
              <div className={styles.comments}>{comment.body.content[0].content.map((item) =>
                <span>
                  {item.type === "text" && <span>{item?.text}</span>}
                  {item.type === "inlineCard" && <a href={item?.attrs?.url} className={styles.link}>{item?.attrs?.url}</a>}
                  {item.type === "mention" && <span className={styles.tag}>{item?.attrs?.text}</span>}
                </span>
              )}</div>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
}

export default Comments;
