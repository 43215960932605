import React from "react";
import { Table } from "antd";
import ArcIcon from "../icon";
import { Resizable } from "react-resizable";
import { serializeColumns, columnsAdd } from "./utils";
import styles from "./datatable.module.scss";

const ResizeableTitle = (props) => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

class ArcDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: false,
      hasData: true,
      top: "none",
      bottom: "bottomRight",
      columns: serializeColumns(this.props.data.columns, props.onRowAction),
      selectedRowKeys: [],
    };

    this.defaultProps = {
      rowSelection: props.rowSelection ? props.rowSelection : true,
    };
  }

  components = {
    header: {
      cell: ResizeableTitle,
    },
  };

  handleResize =
    (index) =>
      (e, { size }) => {
        this.setState(({ columns }) => {
          const nextColumns = [...columns];
          nextColumns[index] = {
            ...nextColumns[index],
            width: size.width,
          };
          return { columns: nextColumns };
        });
      };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  expandedRowRender = () => {
    let colHeader = serializeColumns(this.props.expandable.columns).map(
      (col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
          width: column.width,
          onResize: this.handleResize(index),
        }),
        title: () => (
          <div>
            <span className={styles.displayName}>{col["displayName"]}</span>
          </div>
        ),
      })
    );

    return (
      <Table
        showHeader={this.props.expandable.showHeader}
        columns={colHeader}
        dataSource={this.props.expandable.rows}
        pagination={false}
      />
    );
  };

  render() {
    const { columns, selectedRowKeys, ...state } = this.state;
    const { data, onRow, customColumns, height, overflow } = this.props;

    let colHeader = columns.map((col, index) => ({
      ...col,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
      title: () => (
        <div>
          <span className={styles.displayName}>{col["displayName"]}</span>
        </div>
      ),
    }));
    // let rowSelection = {};
    // if(this.props.rowSelection){
    //   rowSelection['rowSelection'] = selectedRowKeys;
    //   rowSelection['onChange'] = this.onSelectChange;
    // }else{
    //   rowSelection= false;
    // }

    if (customColumns) {
      colHeader = columnsAdd(colHeader, customColumns);
    }
    let expandable = {};
    if (this.props.expandable) {
      expandable["expandedRowRender"] = this.expandedRowRender;
      expandable["defaultExpandedRowKeys"] = [0];
      expandable["expandIcon"] = ({ expanded, onExpand, record }) =>
        expanded ? (
          <ArcIcon
            name="arrow-down"
            size={{ width: 12, height: 12 }}
            onClick={(e) => onExpand(record, e)}
          />
        ) : (
          <ArcIcon
            name="arrow-right"
            size={{ width: 12, height: 12 }}
            onClick={(e) => onExpand(record, e)}
          />
        );
    }

    return (
      <div className={styles.dataWrapper}>
        <Table
          {...this.state}
          rowKey={"id"}
          pagination={false}
          expandable={expandable}
          columns={colHeader}
          dataSource={state.hasData ? data.rows : null}
          components={this.components}
          rowSelection={this.props.rowSelection}
          onRow={(record, index) => ({
            onClick: () => {
              onRow ? onRow(record, index) : (this.onRow = () => { });
            },
          })}
          scroll={{
            x: "max-content",
            y: height
              ? height
              : `calc(100vh - ${overflow ? overflow : "57.5vh"} )`,
          }}
        />
        {/* <div className={styles.paginationWrapper}>
          <div className={styles.entries}>
            <span>Showing 1 to 10 of 30 entries</span>
          </div>
          <Pagination defaultCurrent={1} total={100}/>
        </div> */}
      </div>
    );
  }
}

export default ArcDataTable;
