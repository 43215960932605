
export async function getAllUsers() {

    try {
        const response = await fetch('/api/users');
        return await response.json();
    } catch (error) {
        return [];
    }

}


export async function getIssueById(id) {
    try {
        const response = await fetch('/api/issue?' + new URLSearchParams({
            issueId: id,
        }))
        return await response.json();
    } catch (error) {
        return [];
    }

}

export async function getIssueComments(id) {
    try {
        const response = await fetch('/api/issue/comments?' + new URLSearchParams({
            issueId: id,
        }))
        return await response.json();
    } catch (error) {
        return [];
    }

}

export async function createUser(data) {
    const response = await fetch(`/api/user`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user: data })
    })
    return await response.json();
}