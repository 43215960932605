import React, { useState, useEffect } from 'react';
import { toast } from "react-toastify"
import { MailOutlined, PhoneOutlined, LinkedinOutlined, YoutubeOutlined, FacebookOutlined, InstagramOutlined, TwitterOutlined } from '@ant-design/icons';
import { Layout, ConfigProvider, Menu, Empty, theme, Button, Collapse, Input } from 'antd';
import ArcIcon from './components/icon';
import { ToastContainer, Slide } from 'react-toastify';
import { MENU } from './constant';
import ArcDataTable from './components/datatable';
import DateFomate from './components/datatable/components/date';
import Status from './components/datatable/components/status';
import Priority from './components/datatable/components/priority';
import Comments from './components/datatable/components/comments';
import Description from './components/datatable/components/description';
import { COLUMNS } from './data';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import styles from './app.module.scss';

import { getIssueById } from './services'

const { Header, Content, Footer } = Layout;
const { Search } = Input;
const { Panel } = Collapse;

function App() {

  const [issueInfo, setIssueInfo] = useState();
  const [current, setCurrent] = useState('Support');
  const [searchText, setSearchText] = useState();
  const [ticketId, setTicketId] = useState();

  useEffect(() => {
    if (window.location.href) {
      let list = window.location.href.split('/');
      let id = list[list.length - 1];
      if (id.indexOf('-') > 0) {
        getIssue(id);
        setTicketId(id);
      }
    }
  }, [])

  const getIssue = (id) => {
    setIssueInfo();
      getIssueById(id).then(issues => {
        setIssueInfo()
        if (issues.length === 0 || issues?.error) {
          toast.error(`Please Enter Valid Incident Id`);
          return;
        } else {
          let issueInfoList = [{
            summary: issues?.fields?.summary,
            issuetype: issues?.fields?.issuetype?.name,
            priority: issues?.fields?.priority?.name,
            description: issues?.fields?.description,
            status: issues?.fields?.status?.name,
            comments: issues?.fields?.comment?.comments,
            created: issues?.fields?.created,
          }]
          setIssueInfo(issueInfoList)
        }
  
      });
   
  }

  const onSearch = (value) => {
    if (value) {
      getIssue(value);
      setTicketId(value);
    } else {
      setSearchText();
    }

  };


  const onClick = (e) => {
    setCurrent(e.key);
    window.open(e.key, '_blank').focus();
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken(
    { colorPrimary: "#23A455" }
  );

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00b96b',
          borderRadius: 6
        },
      }}
    >
      <Layout>
        <Header
          className={styles.header}
          style={{
            background: colorBgContainer,
          }}
        >
          <div className={styles.logo}>
            <ArcIcon name="logo" size={{ width: 100, height: 50 }} />
          </div>
          <div className={styles.menu}>
            <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={MENU} />
            <Button shape="round" type='primary'> Contact Sales</Button>
          </div>

        </Header>

        <Content
          className={styles.sitelayout}>
          <div
            style={{
              padding: 24,
              minHeight: 'calc(100vh - 320px)',
              background: colorBgContainer,
            }}
          >
            <div className={styles.search}>
              <div className={styles.label}>Please enter your incident number</div>
              <Search
                placeholder="Incident Number"
                // allowClear
                enterButton="Search"
                value={searchText}
                size="large"
                onSearch={onSearch}
              />
            </div>
            <div className={styles.dataTable}>
             {ticketId&& <h4>INCIDENT ID - {ticketId}</h4>}
              {issueInfo && <ArcDataTable
                overflow={'40rem'} data={{ columns: COLUMNS, rows: issueInfo }} />}
              {!issueInfo && <Empty description={false} />}
            </div>
            <div className={styles.dataTableMobile}>
              {!issueInfo && <Empty description={false} />}
              {issueInfo && <Collapse defaultActiveKey={['1', '2', '3', '4', '5', '6']} >
                <Panel header="Summary" key="1">
                  <p>{issueInfo[0]?.summary}</p>
                </Panel>
                <Panel header="Priority" key="2">
                  <Priority data={issueInfo[0]?.priority} />
                </Panel>
                <Panel header="Status" key="3">
                  <Status data={issueInfo[0]?.status} />
                </Panel>
                <Panel header="Description" key="4">
                  <Description data={issueInfo[0]?.description} />
                </Panel>
                <Panel header="Comments" key="5">
                  <Comments data={issueInfo[0]?.comments} />
                </Panel>
                <Panel header="Created At" key="6">
                  <DateFomate data={issueInfo[0]?.created} />
                </Panel>
              </Collapse>}
            </div>
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
            minHeight: "240px",
            padding: 0,
            background: "#292E31"
          }}
        >
          <div className={styles.footer}>
            <div className={styles.items}>
              <div className={styles.aboutus}>
                <h6>About us</h6>
                <p>We are retail industry experts passionate about delivering intelligent digital commerce software solutions that empower modern retailers and brands to compete online.</p>
              </div>
              <div className={styles.contact}>
                <h6>Contact us</h6>
                <ul>
                  <li>
                    <MailOutlined />
                    <a target="_blank" rel="noopener noreferrer" href="mailto:sales@retisio.com">
                      Email Us
                    </a>
                  </li>
                  <li>
                    <PhoneOutlined />
                    <span>+1 888-627-7657</span>
                  </li>
                </ul>
              </div>
              <div className={styles.follow}>
                <h6>Follow us</h6>
                <ul>
                  <li> <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/retisio/"><LinkedinOutlined /></a></li>
                  <li> <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/c/RETISIOInc"><YoutubeOutlined /></a></li>
                  <li> <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/retisioecommerce"><FacebookOutlined /></a></li>
                  <li> <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/retisio.inc/"><InstagramOutlined /></a></li>
                  <li> <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/retisioinc"><TwitterOutlined /></a></li>
                </ul>
              </div>
            </div>
            <div className={styles.copy}>
              © 2023 RETISIO | All rights reserved | Privacy policy
            </div>
          </div>
        </Footer>
      </Layout>
      <ToastContainer position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        transition={Slide}
        theme='dark'
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover />
    </ConfigProvider>
  );
}

export default App;
