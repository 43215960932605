
const MENU = [
    {
        label: 'Products',
        key: 'Products',
        children: [
            {
                label: 'RETISIO Commerce',
                key: 'https://www.retisio.com/retisio-commerce-cloud/',
            },
            {
                label: 'RETISIO Promotions',
                key: 'https://www.retisio.com/retisio-promotions/',
            },
            {
                label: 'RETISIO Intelligent Search',
                key: 'https://www.retisio.com/intelligent-search/',
            },
            {
                label: 'RETISIO Intelligent Recommendations',
                key: 'https://www.retisio.com/intelligent-recommendations/',
            },
            {
                label: 'RETISIO Analytics',
                key: 'https://www.retisio.com/retisio-analytics/',
            }
        ],
    },
    {
        label: 'Technology',
        key: 'technology',
    },
    {
        label: 'Resources',
        key: 'resources',
        children: [
            {
                label: 'Blogs',
                key: 'https://www.retisio.com/retisio-blog/',
            },
            {
                label: 'Video Library',
                key: 'https://www.retisio.com/video-library/',
            },
            {
                label: 'Case Study',
                key: 'https://www.retisio.com/case-study/',
            },
            {
                label: 'Whitepapers',
                key: 'https://www.retisio.com/whitepapers/',
            },
            {
                label: 'Podcast',
                key: 'https://www.retisio.com/unlock-ecommerce-podcast/',
            },
            {
                label: 'Webinars',
                key: 'https://www.retisio.com/webinars/',
            },
        ],
    },
    {
        label: 'Partners',
        key: 'Partners',
    },
    {
        label: 'Company',
        key: 'Company',
        children: [
            {
                label: 'About',
                key: 'https://www.retisio.com/about/',
            },
            {
                label: 'News & Events',
                key: 'https://www.retisio.com/news/',
            },
            {
                label: 'Careers',
                key: 'https://www.retisio.com/careers/',
            },
            {
                label: 'Contact',
                key: 'https://www.retisio.com/contact/',
            }
        ],
    },
    {
        label: 'Support',
        key: 'Support',
    },
];

export { MENU }