import React from "react";

import styles from "./status.module.scss";

function Status(props) {
  let { data } = props;
  return (
    <div className={styles.common}>
      <div className={`${styles.wrapper} ${styles["_" + data?.replaceAll(' ', '_')]}`}>
        {data}
      </div>
    </div>
  );
}

export default Status;

