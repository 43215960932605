import React from "react";
import { Typography } from 'antd';

import _concat from "lodash/concat";
import DateFomate from "./components/date";
import Priority from "./components/priority";
import Status from "./components/status";
import Comments from "./components/comments";
import Description from "./components/description";
import IssueType from "./components/type";
import styles from './datatable.module.scss';
const { Text } = Typography;

const serializeColumns = (columns, onRowAction) => {
  let ellipsis = true;
  columns.map(function (column) {
    column.dataIndex = column.id;
    column.key = column.id;
    column.ellipsis = true;
    column.sorter = false;
    column.width = 180;
    if (column?.fixed) {
      let fixed = column?.fixed;
      column.fixed = fixed;
    }

    column.render = (data, row, dataIndex) => {
      let dataType = <div className={styles.whiteSpace}>
        {data}
      </div>;
      if (column?.isArray) {
        let name = column.name;
        let data = row;
        for (let item in name) {
          if (data[name[item]]) {
            data = data[name[item]];
          }
        }
        if (typeof data === "string") {
          dataType = <Text
            style={ellipsis ? { width: 250 } : undefined}
            ellipsis={ellipsis ? { tooltip: { data } } : false}
          >
            {data}
          </Text>;
        }
      }
      if (column.widget === "date") {
        dataType = <DateFomate data={data} row={row} />;
      } else if (column.widget === "comments") {
        dataType = <Comments data={data} row={row} />;
      } else if (column.widget === "description") {
        dataType = <Description data={data} row={row} />;
      } else if (column.widget === "status") {
        dataType = (
          <Status
            custom={column?.custom ? column?.custom : "default"}
            data={data}
            row={row}
          />
        );
      } else if (column.widget === "priority") {
        dataType = <Priority data={data} row={row} />;
      } else if (column.widget === "type") {
        dataType = <IssueType data={data} row={row} />;
      }

      return dataType;
    };
    return column;
  });
  return columns;
};

function columnsAdd(allcolumns, columnList) {
  columnList = columnList.map(function (colObject, index) {
    return colObject;
  });
  return _concat(allcolumns, columnList);
}

export { columnsAdd, serializeColumns };
