import React, { useState } from "react";
import { Modal, Button, Typography } from 'antd';
import ArcIcon from "../../../icon";

import styles from "./type.module.scss";
const { Paragraph } = Typography;
function Description(props) {
  let { data } = props;

  let content = data?.content[0]?.content[0]?.text;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  let ellipsis = true;
  return (
    <div className={styles.wrapper}>
      <div className={styles.whiteSpace}>
        {content}
      </div>
     
      <Modal onCancel={handleCancel} width="600px" title="Description" open={isModalOpen}
        footer={[
          <Button key="back" type="primary" onClick={handleCancel}>
            Close
          </Button>,

        ]}
      >
        {data?.content.map((cxp, i) => (
          cxp?.content.map((cx, i) => (
            <p>{cx?.text}</p>
          ))
        ))}
      </Modal>
    </div>
  );
}

export default Description;
