import React from "react";
import SVG from "react-inlinesvg";
import styles from "./icon.module.scss";

function ArcIcon(props) {
  let { onClick, size, name, hover, className } = props;

  size = {
    height: size?.height ? size.height : 16,
    width: size?.width ? size.width : 16,
  };

  try {
    return (
      <div
        className={`${styles.wrapper} ${onClick || hover ? styles.pointer : ""
          }
      ${className ? className : ""} `}
        onClick={onClick && onClick}
      >
        <SVG
          src={`${process.env.PUBLIC_URL}/icons/${name}.svg`}
          width={size.width}
          height={size.height}
          title={name}
        />
      </div>
    );
  } catch (e) {
    let err = `Icon Error: ${!name ? "name is missing in prop" : e.message}`;
    return <span title={err}>icon error</span>;
  }
}

export default ArcIcon;
